<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <ProjectUserStoryForm
      :project-user-story="newProjectUserStory"
      :errors="projectUserStoryValidationErrors"
      :disabled="
        $store.getters.loading[`put:api/convert-issue-to-user-story/${editedProjectIssue.id}`]
      "
      @clear:errors="CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS"
      @cancel="onCancel"
      @save="onSave"
      @upload:attachments="pendingAttachments = $event"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ProjectUserStoryForm from '@/components/forms/ProjectUserStoryForm';
import store from '@/store';

export default {
  name: 'CreateProjectUserStoryFromIssue',

  components: { ProjectUserStoryForm },

  data() {
    return {
      pendingAttachments: [],
    };
  },

  computed: {
    ...mapState('projectUserStories', ['newProjectUserStory', 'projectUserStoryValidationErrors']),
    ...mapState('projectIssues', ['editedProjectIssue']),
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('projectIssues/editProjectIssue', +to.params.projectIssueId)
      .then(() => {
        next();
      })
      .catch(() => {
        next(to.meta.goBack(to.params.projectId));
      });
  },

  created() {
    this.SET_NEW_PROJECT_USER_STORY({
      project_id: this.editedProjectIssue.project_id,
      title: this.editedProjectIssue.title,
      info: this.editedProjectIssue.description,
      status: 'new',
    });
    this.SET_PROJECT_USER_STORY_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('projectUserStories', [
      'storeProjectUserStory',
      'uploadProjectUserStoryAttachments',
    ]),
    ...mapActions('projectIssues', ['convertProjectIssueToUserStory']),
    ...mapMutations('projectUserStories', [
      'SET_NEW_PROJECT_USER_STORY',
      'SET_PROJECT_USER_STORY_VALIDATION_ERRORS',
      'CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS',
    ]),

    onCancel() {
      this.goBack();
    },

    onSave(projectUserStory) {
      this.convertProjectIssueToUserStory({
        projectIssue: this.editedProjectIssue,
        projectUserStory,
      }).then(({ project_user_story }) => {
        if (this.pendingAttachments.length) {
          this.uploadProjectUserStoryAttachments({
            projectUserStory: project_user_story,
            attachments: this.pendingAttachments,
          });
        }

        this.goBack();
      });
    },

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute(this.$route.params.projectId));
    },
  },
};
</script>
